$(function () {
	APP.init();

	// init
	lozadInit();
	fancyboxInit();
	// countUpInit();

	accordionInit();

	hoverToggle();
	trademarkHover();
	recruitmentViewmore();

	$('.product-list .ajaxlink').on('click', function(){
		$('.product-list .ajaxlink').removeClass('active');
		$(this).addClass('active');
	})
});

$(window).on("scroll", function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	backdrop = $(".backdrop"),
	backdropToggle = $(".toggle-backdrop"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	buttonSearch = $(".button-search"),
	mobileWrap = $(".mobile-wrap"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		$(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > outerHeightWindow - heightHeader
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backdrop: () => {
		backdrop.on("click", function () {
			$(this).removeClass("open");
			backdropToggle.removeClass("open");
			body.removeClass("overflow-hidden");
		});
	},
	backToTop: () => {
		backToTop.on("click", function () {
			$("html, body").animate({ scrollTop: 0 }, 500);
		});
	},
	megaBrandMenu: () => {
		$('header .main-menu > li.has-children.is-manufacturer').appendTo('header .main-menu li.has-mega-menu .mega-menu')
	},
	mapping: () => {
		const mainMenu = $("header .navbar-nav").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: "header .header-right",
			desktopMethod: "prependTo",
			breakpoint: 1279.98,
		});
		const productZoneGroup = $(".product-list .product-group").mapping({
			mobileWrapper: ".product-list .global-title",
			mobileMethod: "prependTo",
			desktopWrapper: ".product-list .filder-product",
			desktopMethod: "prependTo",
			breakpoint: 0,
		});
	},
	toggleDropdown: () => {
		$(".dropdown-toggle").on("click", function () {
			$(this).parents(".dropdown").toggleClass("active");
		});
	},
	toggleMenuMobile: () => {
		$(".icon-dropdown").on("click", function () {
			$(this).toggleClass("active");
			$(this).parent().find("> .mega-menu").slideToggle();
			$(this).parent().find("> .sub-menu").slideToggle();
			// $(this).parents('.has-children').find("> .children-menu").slideToggle();
		});
	},
	toggleElm: (button, elmToggle) => {
		button.on("click", function () {
			$(this).toggleClass("open");
			elmToggle.toggleClass("open");
			backdrop.toggleClass("open");
			body.toggleClass("overflow-hidden");
		});
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.megaBrandMenu();
		APP.mapping();
		APP.toggleDropdown();
		APP.toggleMenuMobile();
		APP.toggleElm(buttonMenu, mobileWrap);
		APP.toggleElm(buttonSearch, searchWrap);
	},
};

const homeBanner = new Swiper(".home-banner .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 15,
	pagination: {
		el: ".home-banner .swiper-pagination",
		clickable: true,
	},
	navigation: {
		nextEl: ".home-banner .button-next",
		prevEl: ".home-banner .button-prev",
	},
});

const homeProductSlider = new Swiper(".home-product .swiper", {
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 10,
	slidesPerView: 2,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-product .button-next",
		prevEl: ".home-product .button-prev",
	},
	pagination: {
		el: ".home-product .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1280: {
			slidesPerView: 4,
			spaceBetween: 32,
		},
	},
});

const homeProjectBg = new Swiper(".home-project .swiper-bg", {
	loop: true,
	speed: 1000,
	spaceBetween: 10,
	slidesPerView: 1,
});

const homeProjectThumb = new Swiper(".home-project .swiper-thumb", {
	loop: true,
	speed: 1000,
	watchSlidesProgress: true,
	spaceBetween: 10,
	slidesPerView: 3,
});

const homeProjectTop = new Swiper(".home-project .swiper-top", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 10,
	thumbs: {
		swiper: homeProjectThumb,
	},
	pagination: {
		el: ".home-project .swiper-pagination",
		clickable: true,
	},
	navigation: {
		nextEl: ".home-project .button-next",
		prevEl: ".home-project .button-prev",
	},
});

homeProjectTop.controller.control = homeProjectBg;

const homeCustomer = new Swiper(".home-customer .swiper", {
	// slidesPerView: 2,
	// grid: {
	// 	rows: 3,
	// },
	// spaceBetween: 8,
	spaceBetween: 10,
	pagination: {
		el: ".home-customer .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	navigation: {
		nextEl: ".home-customer .button-next",
		prevEl: ".home-customer .button-prev",
	},
	// breakpoints: {
	// 	576: {
	// 		slidesPerView: 3,
	// 	},
	// 	1024: {
	// 		slidesPerView: 4,
	// 	},
	// 	1280: {
	// 		slidesPerView: 5,
	// 	},
	// },
});

const homeNews = new Swiper(".home-news .swiper", {
	slidesPerView: 1,
	spaceBetween: 30,
	pagination: {
		el: ".home-news .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	navigation: {
		nextEl: ".home-news .button-next",
		prevEl: ".home-news .button-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
			spaceBetween: 15,
		},
		768: {
			slidesPerView: 3,
			spaceBetween: 15,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 20,
		},
		1280: {
			slidesPerView: 4,
			spaceBetween: 33,
		},
	},
});

const homeBrand = new Swiper(".home-brand .swiper", {
	slidesPerView: 2,
	spaceBetween: 30,
	pagination: {
		el: ".home-brand .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	navigation: {
		nextEl: ".home-brand .button-next",
		prevEl: ".home-brand .button-prev",
	},
	breakpoints: {
		576: {
			slidesPerView: 3,
			spaceBetween: 15,
		},
		768: {
			slidesPerView: 3,
			spaceBetween: 15,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 22,
		},
		1280: {
			slidesPerView: 5,
			spaceBetween: 32,
		},
	},
});

const productDetailThumb = new Swiper(".product-detail .swiper-thumb .swiper", {
	slidesPerView: 6,
	watchSlidesProgress: true,
});

const productDetailMain = new Swiper(".product-detail .swiper-main .swiper", {
	slidesPerView: 1,
	spaceBetween: 5,
	thumbs: {
		swiper: productDetailThumb,
	},
	navigation: {
		nextEl: ".product-detail .swiper-main .button-next",
		prevEl: ".product-detail .swiper-main .button-prev",
	},
});

const projectDetail = new Swiper(".project-detail-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	slidesPerView: 1,
	navigation: {
		nextEl: ".project-detail-swiper .button-next",
		prevEl: ".project-detail-swiper .button-prev",
	},
});

const projectOther = new Swiper(".project-other-swiper .swiper", {
	slidesPerView: 1,
	spaceBetween: 32,
	navigation: {
		nextEl: ".project-other-swiper .button-next",
		prevEl: ".project-other-swiper .button-prev",
	},
	pagination: {
		el: ".project-other-swiper .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
		},
		1024: {
			slidesPerView: 3,
		},
	},
});

const about4 = new Swiper(".about-4-swiper .swiper", {
	pagination: {
		el: ".about-4-swiper .swiper-pagination",
		clickable: true,
		// dynamicBullets: true,
	},
	navigation: {
		nextEl: ".about-4-swiper .button-next",
		prevEl: ".about-4-swiper .button-prev",
	},
});

const about1 = new Swiper(".about-1-swiper .swiper", {
	watchSlidesProgress: true,
	slidesPerView: 1,
	navigation: {
		nextEl: ".about-1-swiper .button-next",
		prevEl: ".about-1-swiper .button-prev",
	},
	pagination: {
		el: ".about-1-swiper .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
		},
		1024: {
			slidesPerView: 3,
		},
	},
});

const productOther = new Swiper(".product-other .swiper", {
	slidesPerView: 1,
	spaceBetween: 32,
	navigation: {
		nextEl: ".product-other .button-next",
		prevEl: ".product-other .button-prev",
	},
	pagination: {
		el: ".product-other .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
		},
		1024: {
			slidesPerView: 3,
		},
		1280: {
			slidesPerView: 4,
		},
	},
});

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad(".lozad", {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad(".lozad-bg", {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function checkCookie(cookieName) {
	let cookie = getCookie(cookieName);
	$(".exp a").on("click", function () {
		var dataExp = $(this).data("exp");
		setCookie("exp", dataExp, 1);
	});
	if (cookie != "") {
	} else {
	}
}

function countUpInit() {
	$(".count-up").each(function (index) {
		$(this).attr("id", `countUp-${index}`);
		const endVal = $(this).data("count");

		const options = {
			separator: ".",
			enableScrollSpy: true,
			scrollSpyDelay: 1000,
			scrollSpyOnce: true,
		};

		const countUp = new CountUp(`countUp-${index}`, endVal, options);

		if (!countUp.error) {
			countUp.start();
		} else {
			console.error(countUp.error);
		}

		countUp.handleScroll();
	});
}

var numberList = document.querySelector(".number-list");
if (numberList)
	new Waypoint({
		element: numberList,
		handler: function () {
			countUpInit();
			//this.destroy() //for once
		},
		offset: "50%",
	});

function accordionInit() {
	$(".accordion-item").on("click", function () {
		$(this).toggleClass("active").siblings().removeClass("active");
		$(this)
			.find(".accordion-header")
			.toggleClass("active")
			.parents(".accordion-item")
			.siblings()
			.find(".accordion-header")
			.removeClass("active");
		$(this)
			.find(".accordion-content")
			.slideToggle()
			.parents(".accordion-item")
			.siblings()
			.find(".accordion-content")
			.slideUp("active");
	});
}

function loadItemInit() {
	$(".load-item-init").each(function (index) {
		let showItem = $(this).data("show");
		let itemEml = $(this).find(".load-item");
		let btnLoad = $(this).find(".btn-load-more");
		if (itemEml.length <= showItem) {
			btnLoad.fadeOut("slow");
		}
		itemEml.slice(0, showItem).show();
		btnLoad.on("click", function (e) {
			e.preventDefault();
			let itemEmlHide = $(this)
				.parents(".load-item-init")
				.find(".load-item:hidden");
			itemEmlHide.slice(0, showItem).slideDown();
			let countItemEmlHide = $(this)
				.parents(".load-item-init")
				.find(".load-item:hidden");
			if (countItemEmlHide.length <= 0) {
				btnLoad.fadeOut("slow");
			}
		});
	});
}

function shareSocialPopup() {
	$(".share-popup").on("click", function (e) {
		e.preventDefault();
		window.open(
			$(this).attr("href"),
			"popupWindow",
			"width=500,height=500,scrollbars=yes,resizable=yes,left=100,top=100"
		);
	});
}

function hoverToggle() {
	$(".hover-toggle-item")
		.on("mouseenter", function () {
			$(this).find(".content-toggle").slideDown();
		})
		.on("mouseleave", function () {
			$(this).find(".content-toggle").slideUp();
		});
}

function trademarkHover() {
	$(".home-trademark .trademark-item")
		.on("mouseenter", function () {
			let idBg = $(this).data("trademark-bg");
			$(`.home-trademark .trademark-bg-item[data-trademark-bg='${idBg}']`)
				.addClass("active")
				.siblings()
				.removeClass("active");
		})
		.on("mouseleave", function () {
			$(`.home-trademark .trademark-bg-item`).removeClass("active");
		});
}

function recruitmentViewmore() {
	$btnViewmore = $(".recruitment-3 .view-more");
	$item = $(".recruitment-3 .list>.item");
	$totalView = 6;

	$item.each(function (index) {
		if (index > $totalView - 1) {
			$(this).addClass("hidden");
		}
	});

	$itemHidden = $(".recruitment-3 .list>.item.hidden");
	if (($itemHidden.length = 0)) {
		$btnViewmore.hide();
	}

	$btnViewmore.on("click", function () {
		$item.slideDown();
		$item.removeClass("hidden");

		$itemHidden = $(".recruitment-3 .list>.item.hidden");
		console.log($itemHidden.length = 0);
		if ($itemHidden.length === 0) {
			$btnViewmore.hide();
		}
	});
}
